<template>
  <div class="booking" v-if="unwantedDates.length">
    <h3 class="section-title booking__title">Нежелаемые даты</h3>
    <ul>
      <li v-for="date in unwantedDates" :key="date">
        {{ getDateInFormat(date.unwanted_from) }} -
        {{ getDateInFormat(date.unwanted_to) }}
      </li>
    </ul>
  </div>
</template>

<script>
import { getDateInFormat } from "@/utils/dateTime";

export default {
  name: "UnwantedDates",
  props: {
    unwantedDates: {
      type: Array,
      default: () => {return []}
    }
  },
  methods: {
    getDateInFormat
  }
};
</script>