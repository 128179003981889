<template>
  <div class="place-details">
    <h3 class="section-title">Правила жилья</h3>
    <ul>
      <li v-for="rule in rules.filter((rule) => rule.type !== 'text')" :key="rule.id">
        {{ rule.title }}
      </li>
    </ul>
    <div v-if="landlordRules">
      <h4>Дополнительные правила жилья:</h4>
      <p>{{ landlordRules.text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rules: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    landlordRules() {
      return this.rules.find((rule) => rule.id === 41)
    }
  }
};
</script>

<style scoped>
ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 24px 0;
  gap: 12px;
}

li {
  /*flex: 1 1 auto;*/
  padding: 12px 24px;
  border-radius: 24px;
  background: #1AC386;
  color: white;
}
</style>