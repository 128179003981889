<template>
	<section class="place-description-section">
		<div class="container">
			<div class="place-description">
				<tk-button @click="goBack" kind="back-button">
					<template #icon-before>
						<tk-svg type="arrow-top" />
					</template>
					Назад
				</tk-button>

				<div class="place-description__status status">
					<p class="status__location">
						{{ data.address }} <i class="icon icon-pin"></i>
					</p>
					<div class="status__wrapper" v-if="!upcomingTripMode && $store.getters.isAuth">
						<p
							@click="updateFavourite(data.id)"
							:class="{ active: data.is_favorite }"
							class="status__item fav-btn"
						>
							<tk-svg type="fav" />
							{{ data.is_favorite ? 'Убрать из избранного' : 'В избранное' }}
						</p>
					</div>
				</div>

				<Carousel
					:is-not-draft="isSelf"
					:verifed_at="data.verifed_at"
					:id-value="data.id"
					:content="data.images"
				/>

				<div class="place-description__wrapper">
					<div
						class="place-description__column place-description__column--first"
					>
						<div class="place-description__info">
							<h2 class="section-title" v-if="!upcomingTripMode">
								{{ data.title }}
							</h2>
							<div class="address" v-if="data.address && !upcomingTripMode">
								<div class="address__text">
									{{ data.address }}
								</div>
								<div class="address__copy-icon">
									<img
										src="@/assets/images/svg/copy.svg"
										alt="Копировать адрес"
										@click="copyAddress"
										decoding="async"
										width="15"
										height="15"
									/>
									<div class="tooltip">
										<div class="tooltip__content" v-if="tooltipActive">
											Адрес скопирован
										</div>
									</div>
								</div>
							</div>
							<Owner
								:userId="data.user_id"
								:isBooking="isBooking"
								:reviewsCount="data.reviews_count"
                :placeTitle="data.title"
                :placeImage="(data.images && data.images[0] && data.images[0].image) ? data.images[0].image.jpg['480']['1x'] : ''"
							/>
							<About
								:type="data.type"
								:spaceBath="data.space_bath"
								:spaceKitchen="data.space_kitchen"
								:spaceSleep="data.space_sleep"
								:roomType="data.room_type"
							/>
							<Description
								v-if="data.description"
								:content="data.description"
							/>
							<Rooms
								:room-type="data.room_type"
								:content="data.rooms"
								:is-booking="isBooking"
								:reserves="reserves"
                v-if="data.room_type && data.room_type !== 1"
							/>
							<Booking
								v-if="data.settings"
								:min-days-booking="data.settings.min_days_booking"
								:free-cancellation-days="data.settings.free_cancellation_days"
                :check-in-time="data.settings.check_in_time"
                :check-out-time="data.settings.check_out_time"
							/>
              <!-- нежелательные даты; только при просмотре своего объвления после редактирования -->
              <UnwantedDates
                v-if="isSelf"
                :unwanted-dates="landlordReserves"
              />
							<AmenitiesBlock :content="data.amenities" />
							<!-- id="place-location" -->
							<Location
								v-if="data.location"
								:location="data.location"
								:address="data.address"
                :address-description="data.address_description"
							/>
              <Rules :rules="data.rules" v-if="data.rules" />
<!--							<Details-->
<!--								v-if="isShowDetails"-->
<!--								:more="data.more"-->
<!--								:rules="data.rules"-->
<!--							/>-->
              <Reviews
                v-if="data.reviews_count"
                :count="data.reviews_count"
                :rating="data.rating"
                :ratings="data.ratings"
                :place-id="data.id"
                :review="data.review_last"
              />
						</div>
					</div>

					<div
						v-if="$store.getters.isAuth"
						class="place-description__column place-description__column--second"
					>
						<PriceSidebar
							:additional-amenities="additionalAmentities"
							:price="data.price"
							:count-sleep-place="countSleepPlace"
							:room-type="data.room_type"
							:placeId="placeId"
							:rooms="data.rooms"
							:reserves="reserves"
							:reviewsCount="data.reviews_count"
							:rating="data.rating"
							@updateReservation="getReservation"
							v-if="!isBooking && !isSelf"
						/>
						<tk-button
							class="place-description__complaint-btn"
							@click="showComplainModal"
							v-if="!isBooking && !isSelf"
							kind="main-brown"
						>
							Пожаловаться на объявление
						</tk-button>

						<BookingBlock
							v-if="isBooking"
							:settings="data.settings"
							:date-from="reserve.dateFrom"
							:date-to="reserve.dateTo"
						/>
					</div>
					<div v-else>
						<tk-button
							class="place-description__complaint-btn"
							@click="showLoginModal"
						>
							Забронировать
						</tk-button>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import modals from '@/constants/modals'

import PriceSidebar from '@/components/PriceSidebar'

import Carousel from '@/components/views/place/Carousel'
import Reviews from '@/components/views/place/Reviews'
import Owner from '@/components/views/place/Owner'
import About from '@/components/views/place/About'
import Description from '@/components/views/place/Description'
import Booking from '@/components/views/place/Booking'
import BookingBlock from '@/components/views/place/BookingBlock'
import AmenitiesBlock from '@/components/views/place/AmenitiesBlock'
import Location from '@/components/views/place/Location'
import Details from '@/components/views/place/Details'
import Rooms from '@/components/views/place/Rooms'
import Rules from '@/components/views/place/Rules'
import UnwantedDates from "@/components/views/place/UnwantedDates";

import { CREATE } from '@/constants/store/favourites/actions'

// Refactoring

export default {
	name: 'PlaceDescription',
	components: {
		PriceSidebar,
		Carousel,
		Reviews,
		Owner,
		About,
		Description,
		Booking,
		BookingBlock,
		AmenitiesBlock,
		Location,
		Details,
		Rooms,
    UnwantedDates,
    Rules
	},
	data() {
		return {
			/** если происходит просмотр предстоящей поездки **/
			upcomingTripMode: false,
			backPath: '',

			/** скрыты ли контакты хозяином **/
			contactsHidden: false,
			tooltipActive: false,
			isShowShare: false,
			data: {},
			reserves: [],

      // нежелательные даты; только при просмотре своего объвления после редактирования
      landlordReserves: []
		}
	},
  watch: {
    routeDate() {
      this.getReservation()
    }
  },
	async beforeMount() {
		if (window.history.state.back?.includes('places')) {
			this.backPath = window.history.state.back
		}

		const response = await this.$api.place.get({
			id: this.$route.params.slug
		})

		if (response?.status) {
			this.data = {
				...response.response
			}
      this.data.ratings.convenience_location = parseFloat(this.data.ratings.convenience_location)
      this.data.ratings.hospitable = parseFloat(this.data.ratings.hospitable)
      this.data.ratings.sociality = parseFloat(this.data.ratings.sociality)
      this.data.ratings.purity = parseFloat(this.data.ratings.purity)

      const ratings = this.data.ratings

      this.data.rating =
        (ratings.convenience_location + ratings.hospitable +
          ratings.sociality + ratings.purity ) / 4

      if (this.data.rules && this.data.rules.length) {
        let rules = []
        this.data.rules.forEach(rule => {
          if (rule.children.length) {
            rules = rules.concat(rule.children)
          } else {
            rules.push(rule)
          }
        })
        this.data.rules = rules
      }
		}

		await this.getReservation()

    /**
     * за данными по нежелательным датам ходим, только если просматриваем
     * свое собственное объявление после создания
      */
    if (this.isSelf) {
      await this.getLandlordReserves()
    }
	},
	computed: {
		countSleepPlace() {
			return this.data.space_sleep?.reduce(
				(prev, itm) => (prev += itm.value),
				0
			)
		},
		placeId() {
			return this.$route.params.slug
		},
		additionalAmentities() {
			return this.data.amenities
				?.map((itm) => itm.options)
				.flat()
				.filter((itm) => +itm.price)
		},
		reserve() {
			return {
				dateFrom: this.$route.query.date_from,
				dateTo: this.$route.query.date_to
			}
		},
		isShowDetails() {
			return (this.data.more && this.data.more.length) || (this.data.rules && this.data.rules.length)
		},
		isBooking() {
			return this.$route.query.type === 'booking'
		},
		isSelf() {
			return this.$route.query.type === 'self'
		},
    routeDate() {
      return this.$route.query.date_from + this.$route.query.date_to
    }
	},
	methods: {
    // возвращает нежелательные даты в landlordReserves
    async getLandlordReserves() {
      const response = await this.$api.place.getBooking({
        place_id: this.data.id
      })

      if (response?.status) {
        const values = response.response
        if (values.unwanted_dates.length) {
          this.landlordReserves = values.unwanted_dates
        } else {
          this.landlordReserves = []
        }
      }
    },
		goBack() {
      if (this.$route.query.city) {
        this.$router.push({
          path: '/places',
          query: {
            date_from: this.$route.query.date_from,
            date_to: this.$route.query.date_to,
            children: +this.$route.query.children || 0,
            guests: +this.$route.query.guests || 0,
            city: this.$route.query.city,
          }
        })
      } else {
        this.$router.back()
      }
		},
		async getReservation() {
			if (
				!this.$store.getters.isAuth ||
				!this.reserve.dateFrom ||
				!this.reserve.dateTo
			) {
				return
			}

			const reservesReq = this.$api.reserve.getUsersReservesByDate({
				place_id: this.$route.params.slug,
				date_from: this.reserve.dateFrom,
				date_to: this.reserve.dateTo
			})

      const freeDatesReq = this.$api.reserve.getFreeDates({
        place_id: this.$route.params.slug,
        date_from: this.reserve.dateFrom,
        date_to: this.reserve.dateTo
      })

      const [reserves, freeDates] = await Promise.all([reservesReq, freeDatesReq])

			if (reserves?.status && freeDates?.status) {
        const freeBeds = new Set()
        freeDates.response.forEach((room) => {
          room.bed_id.forEach((bed) => freeBeds.add(bed))
        })
        const indexesForDelete = []
        reserves.response.forEach((reserve, index) => {
          if (freeBeds.has(reserve.bed_id)) indexesForDelete.push(index)
        })

        if (indexesForDelete.length) {
          const fixedReserves = []
          let i = 0;
          for (let j = 0; j < reserves.response.length; j++) {
            if (j === indexesForDelete[i]) {
              i++;
            } else {
              fixedReserves.push(reserves.response[j])
            }
          }
          this.reserves = fixedReserves
        } else {
          this.reserves = reserves.response
        }
			}
		},
		showLoginModal() {
			this.$store.commit('showModal', {
				name: modals.AUTH,
				props: null,
				isShow: true
			})
		},
		showComplainModal() {
			this.$store.commit('showModal', {
				name: modals.COMPLAIN_ON_AD,
				props: {
					place_id: this.data.id
				},
				isShow: true
			})
		},
		async updateFavourite(id) {
			let response

			if (!this.data.is_favorite) {
				response = await this.$api.favorites.create({
					places: id
				})
			} else {
				response = await this.$api.favorites.delete({
					place_id: id
				})
			}

			if (response?.status) {
				this.$store.commit('showNotification', {
					type: 'success',
					text: 'Успешно'
				})

				if (!this.data.is_favorite) {
					this.data = { ...this.data, is_favorite: true }
				} else {
					this.data = { ...this.data, is_favorite: false }
				}
			} else {
				this.$store.commit('showNotification', {
					type: 'success',
					text: 'Ошибка запроса'
				})
			}
		},
		copyAddress() {
			navigator.clipboard.writeText(this.data.address).then(() => {
				this.tooltipActive = true

				setTimeout(() => {
					this.tooltipActive = false
				}, 3000)
			})
		}
	}
}
</script>

<style lang="sass">
.share-wrapper
	margin-right: 15px

	.dropdown
		&__content
			z-index: 9
</style>
