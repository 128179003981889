<template>
	<!-- Блок с датами заселения и отъезда отображается при просмотре ПРЕДСТОЯЩЕГО бронирования  -->
	<ul class="place-description__dates">
		<li class="place-description__dates_item">
			<p class="item-name">Заселение</p>
			<p class="item-content">
				{{ getDate(dateFrom) }} – {{ settings.check_in_time }}
			</p>
		</li>
		<li class="place-description__dates_item">
			<p class="item-name">Отъезд</p>
			<p class="item-content">
				{{ getDate(dateTo) }} – {{ settings.check_out_time }}
			</p>
		</li>
	</ul>
	<!-- Блок с кнопками быстрого перехода отображается при просмотре ПРЕДСТОЯЩЕГО бронирования-->
	<!-- <div class="place-description__quick-link">
		<div class="section-title">Быстрый переход</div>
		<a href="#place-location" class="btn quick-link-btn">Как добраться</a>

		<a class="btn quick-link-btn" @click="showReceiptModal">
      Получить чек поездки
    </a>

		<a class="btn quick-link-btn">Получить PDF поездки</a>

		<router-link to="/chat" class="btn quick-link-btn">
      Написать хозяину
    </router-link>
	</div> -->
</template>

<script>
import { getDateInFormat } from '@/utils/dateTime';

export default {
	props: {
		settings: {
			type: Object,
			default: () => ({
				additional_guest_amount: 0,
				auto_booking_confirmation: 0,
				check_in_time: '14:00',
				check_out_time: '12:00',
				cleaning_amount: 0,
				free_cancellation_days: 0,
				guests_with_verified_documents_only: 0,
				min_days_booking: 1,
				percentage_forfeit: 0
			})
		},
		dateFrom: {
			type: String,
			required: true
		},
		dateTo: {
			type: String,
			required: true
		}
	},
	methods: {
		getDate(payload) {
			return getDateInFormat(payload)
		}
	}
}
</script>