<template>
	<div class="descr">
		<h3 class="section-title descr__title">Описание</h3>
		<p class="descr__text">
			{{ content }}
		</p>

    <!-- <tk-button kind="contur-green-button">читать дальше</tk-button> -->
	</div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: null
    }
  }
}
</script>