<template>
	<div class="owner">
		<!-- При просмотре предстоящего бронирования блоку присваивается класс contacts_is_shown-->
		<div
			class="owner__info owner-info"
			:class="{ contacts_is_shown: upcomingTripMode }"
		>
			<div class="owner-info__wrapper">
				<div @click="showDescModalUser" class="owner-info__img">
					<tk-image :src="avatar" :alt="name" />
				</div>
				<div class="owner-info__descr">
					<p class="owner-info__name">{{ name }}</p>

					<!-- <div class="place-address" v-if="upcomingTripMode">
						<div class="address__text">Улица Пушкина, 12, Москва, Россия</div>
						<div class="address__copy-icon">
							<img
								src="@/assets/images/svg/copy.svg"
								alt="Копировать адрес"
								@click="showTooltip"
							/>
							<div class="tooltip-wrapper">
								<div class="tooltip" v-if="tooltipActive">Адрес скопирован</div>
							</div>
						</div>
					</div> -->
					<!-- <a
						v-if="values.phone"
						:href="`tel:${values.phone}`"
						class="owner-phone"
					>
						{{ values.phone }}
					</a>
					<p v-else class="owner-phone">
						Для связи с хозяином необходимо авторизоваться
					</p> -->
					<!-- <a
						href="mailto:michail@mail.ru"
						class="owner-email"
						v-if="upcomingTripMode && !contactsHidden"
						>michail@mail.ru</a
					>
					<a
						class="owner-phone hidden"
						v-if="upcomingTripMode && contactsHidden"
						>скрыт</a
					>
					<a
						class="owner-email hidden"
						v-if="upcomingTripMode && contactsHidden"
						>скрыт</a
					> -->

					<!-- <div class="owner-info__rating rating" v-if="!upcomingTripMode">
						<i class="icon icon-star-1"></i> {{ reviewsCount }} отзывов
					</div> -->
				</div>
			</div>

			<tk-link
				v-if="isShowContactWithOwnerBtn"
				:height="45"
				font-size="15px"
				line-height="100%"
        kind="common-button"
				class="owner-info__contact-btn"
				@click.prevent="handlerContactWithOwner()"
			>
				Связаться с хозяином
			</tk-link>
		</div>
	</div>
</template>

<script>
import modals from '@/constants/modals'

export default {
  inject: ['chat'],
	props: {
		isBooking: {
			type: Boolean,
			default: false
		},
		// rating: {
		// 	type: Number,
		// 	default: 0
		// },
		reviewsCount: {
			type: Number,
			default: 0
		},
		userId: {
			type: String,
			default: null
		},
    placeTitle: String,
    placeImage: String,
	},
	watch: {
		userId: async function () {
			await this.get()
		}
	},
	data() {
		return {
			values: {},
			upcomingTripMode: false
		}
	},
	computed: {
		isShowContactWithOwnerBtn() {
			return (
				this.userId !== this.$store.state.user.id
			)
		},
		name() {
			const { name, surname, patronymic } = this.values

			if (!name && !surname && !patronymic) {
				return 'Пользователь'
			} else {
				return `${surname || ''} ${name || ''} ${patronymic || ''}`
			}
		},
		avatar() {
			return this.values.avatars && this.values.avatars.length
				? this.values.avatars.find((avatar) => avatar.is_active)
				: '/images/default-avatar.svg'
		}
	},
	methods: {
    handlerContactWithOwner() {
			if (this.$store.getters.isAuth) {
				const msg = `Добрый день! Меня заинтересовало данное объявление
					[b] ${this.placeTitle} [/b].[br]
					[teremok place="${this.$route.params.slug}"]Ссылка[/teremok]
					[img]${this.placeImage}[/img]`;
				this.chat.getOrCreateConversationWithUser(this.userId, msg)

				this.$router.push('/profile/chat')
			} else {
				this.$store.commit('showModal', {
					name: modals.AUTH,
					props: null,
					isShow: true
				})
			}
    },
		showDescModalUser() {
			this.$store.commit('showModal', {
				name: modals.DESCRIPTION_USER,
				props: {
					id: this.userId
				}
			})
		},
		async get() {
			if (!this.userId) {
				return
			}

			const response = await this.$api.users.get({ ids: this.userId })

			if (!response.status) {
				return
			}

			this.values = response.response.users[0]
		}
	}
}
</script>
