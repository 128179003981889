<template>
	<div class="place-rooms">
		<h3 class="place-rooms__title">Комната или место проживания</h3>

		<ul class="place-rooms__list">
			<li
				v-for="item in content"
				:key="item.id"
				class="place-rooms__item place-room"
			>
				<Room
					:content="item"
          :is-booking="isBooking"
					:room-type="roomType"
					:sleepPlaces="$store.state.sleepPlaces"
					:reserves="users"
				/>
			</li>
		</ul>
	</div>
</template>

<script>
import Room from './cards/Room.vue'
import { arrToObj } from '@/utils/normalizeData'

export default {
	components: { Room },
	props: {
		roomType: {
			type: Number,
			default: null
		},
    isBooking: {
      type: Boolean,
      default: false
    },
		content: {
			type: Array,
			default: () => []
		},
		reserves: {
			type: Array,
			default: () => []
		}
	},
	async created() {
		await this.$store.dispatch('getSleepPlaces')
	},
	computed: {
		users() {
			return arrToObj(this.reserves, this.roomType === 2 ? 'room_id' : 'bed_id')
		}
	}
}
</script>

<style lang="sass">
.place-rooms
  &__title
    margin: 0
    margin-bottom: 40px
    font-weight: 700
    font-size: 24px
    line-height: 24px
    color: #3B3F47

  &__list
    display: grid
    grid-template-columns: 1fr 1fr
    flex-wrap: wrap
    gap: 20px
  @media screen and (max-width: 479px)
    &__list
      grid-template-columns: 1fr
</style>
