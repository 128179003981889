<template>
	<div class="place-carousel">
		<!-- <a v-if="isNotDraft" @click="editAds" class="btn edit-btn place-carousel__edit">
			Редактировать
		</a> -->

		<div
			class="place-carousel__desktop carousel-desktop"
			:class="{ 'place-carousel__desktop--empty': !preview.length }"
		>
			<ul v-if="preview.length" class="carousel-desktop__list">
				<li
					v-for="(item, index) in preview"
					:key="item.image.hash"
					class="carousel-desktop__item"
					@click="showPlaceCarousel()"
				>
					<tk-image
						v-bind="previewSizes[index]"
						:src="item.image"
						class="carousel-desktop__image"
						alt="Фото жилья"
					/>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import modals from '@/constants/modals'

import { SET } from '@/constants/store/place/mutations'

export default {
	props: {
		content: {
			type: Array,
			default: () => []
		},
		isNotDraft: {
			type: Boolean,
			default: false
		},
		verifed_at: {
			type: String,
			default: null
		},
		idValue: {
			type: Number
		}
	},
	computed: {
    images() {
      return this.content.map((itm) => itm.image);
    },
    preview() {
			return this.content.slice(0, 5)
		},
		previewSizes() {
			return {
				0: {
					width: '530',
					height: '450'
				},
				1: {
					width: '310',
					height: '210'
				},
				2: {
					width: '200',
					height: '220'
				},
				3: {
					width: '200',
					height: '220'
				},
				4: {
					width: '310',
					height: '210'
				}
			}
		}
	},
	methods: {
		showPlaceCarousel() {
			this.$store.commit('showModal', {
				name: modals.PLACE_CAROUSEL,
				props: this.images,
				isShow: true
			})
		},
		editAds() {
			this.$store.commit(SET, { key: 'place_id', value: this.idValue })
			this.$store.commit(SET, { key: 'current', value: 1 })

			setTimeout(() => {
				this.$router.push({ name: 'Place.Create' })
			})
		}
	}
}
</script>

<style lang="sass">
.place-carousel
	position: relative

	&__edit
		position: absolute
		top: 20px
		left: 20px

		width: 140px
		height: 30px

		font-weight: bold
		font-size: 12px
		line-height: 12px

		z-index: 9

		border-radius: 6px

		&:after
			content: url('~@/assets/images/svg/edit-icon-white.svg')
			margin-left: 9px

	&__desktop
		@media (max-width: 800px)
			min-height: 310px

		&--empty
			position: relative

			display: flex
			justify-content: center
			align-items: center

			background-color: #CBCBCB
			border-radius: 20px

			&:before
				content: ''

				position: absolute
				top: 0
				left: 0
				right: 0
				bottom: 0

				width: 100px
				height: 90px
				margin: auto

				background-image: url('~@/assets/img/svg/camera-white.svg')
				background-repeat: no-repeat
				background-position: center
				background-size: 100px 90px
</style>
