<template>
	<div class="booking">
		<h3 class="section-title booking__title">Бронирование</h3>
		<div class="booking__wrap">
			<div class="booking-item">
				<div class="booking-item__name">Минимум ночей:</div>
				<div class="booking-item__value">{{ minDaysBooking }}</div>
			</div>
			<div class="booking-item">
				<div class="booking-item__name">
					Длительность бесплатной отмены бронирования, дней:
				</div>
				<div class="booking-item__value">{{ freeCancellationDays }}</div>
			</div>
      <div class="booking-item">
        <div class="booking-item__name">
          Время заезда:
        </div>
        <div class="booking-item__value">{{ checkInTime }}</div>
      </div>
      <div class="booking-item">
        <div class="booking-item__name">
          Время отъезда:
        </div>
        <div class="booking-item__value">{{ checkOutTime }}</div>
      </div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		freeCancellationDays: {
			type: Number,
			default: 0
		},
		minDaysBooking: {
			type: Number,
			default: 0
		},
    checkInTime: {
      type: String,
      default: "14:00"
    },
    checkOutTime: {
      type: String,
      default: "12:00"
    },
	}
}
</script>