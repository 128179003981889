<template>
	<div class="place-details">
		<h3 class="section-title">Подробности жилья</h3>
		<ul class="place-details__list">
			<li v-if="rules.length" class="place-details__item place-detail">
				<i class="icon icon-house-outline"></i>
				<div class="place-detail__container">
					<h4 class="place-detail__title">Правила дома</h4>
					<tk-button
						kind="link-green"
						@click="showRules"
					>
						Подробнее
					</tk-button>
				</div>
			</li>
			<li v-if="more.length" class="place-details__item place-detail">
				<i class="icon icon-household"></i>
				<div class="place-detail__container">
					<h4 class="place-detail__title">
						Дополнительные услуги, включённые в стоимость.
					</h4>
					<tk-button
						kind="link-green"
						@click="showAdditional"
					>
						Подробнее
					</tk-button>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
import modals from '@/constants/modals'

export default {
	props: {
		rules: {
			type: Array,
			default: () => []
		},
		more: {
			type: Array,
			default: () => []
		}
	},
	methods: {
		showRules() {
			this.$store.commit('showModal', {
				name: modals.PLACE,
				props: {
					title: 'Правила дома',
					value: this.rules
				},
				isShow: true
			})
		},
		showAdditional() {
			this.$store.commit('showModal', {
				name: modals.PLACE,
				props: {
					title: 'Дополнительные услуги, включённые в стоимость.',
					value: this.more
				},
				isShow: true
			})
		}
	}
}
</script>