<template>
	<div class="about-place">
		<h3 class="about-place__title">О жилье</h3>
		<ul class="about-place__list">
			<li class="about-place__item about-item">
				<div class="about-item__row">
					<p class="about-item__title">Тип жилья</p>
					<p class="about-item__text">{{ types[type] }}</p>
				</div>
				<div class="about-item__row">
					<p class="about-item__title">Условия размещения</p>
					<p class="about-item__text">{{ roomTypes[roomType] }}</p>
				</div>
			</li>
			<li v-if="spaceSleep.length" class="about-place__item about-item">
				<p class="about-item__title">Количество спальных мест</p>
				<div class="about-item__description">
					<p class="about-item__text">{{ sleeps }}</p>
					<!-- <button class="about-item__description btn">Подробнее</button> -->
				</div>
			</li>
			<li v-if="commonAreas" class="about-place__item about-item">
				<p class="about-item__title">Зоны общего пользования</p>
				<div class="about-item__description">
					<p class="about-item__text">{{ commonAreas }}</p>
					<!-- <button class="about-item__description btn">Подробнее</button> -->
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	props: {
		type: {
			type: Number,
			default: null
		},
		roomType: {
			type: Number,
			default: null
		},
		spaceSleep: {
			type: Array,
			default: () => []
		},
		spaceKitchen: {
			type: Array,
			default: () => []
		},
		spaceBath: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		types() {
			return {
				1: 'Квартира',
				2: 'Частный дом',
				3: 'Аппартаменты'
			}
		},
		roomTypes() {
			return {
				1: 'Жильё целиком',
				2: 'Отдельная комната',
				3: 'Место в комнате'
			}
		},
		commonAreas() {
			return [this.spaceBath, this.spaceKitchen]
				.flat()
				.reduce((prev, itm) => (prev += `${itm.title}: ${itm.value}, `), '')
				.slice(0, -2)
		},
		sleeps() {
			return this.spaceSleep
				.reduce((prev, itm) => (prev += `${itm.title}: ${itm.value}, `), '')
				.slice(0, -2)
		}
	}
}
</script>
