<template>
	<div class="amenities" v-if="items.length">
		<h3 class="section-title amenities__title">Удобства</h3>
		<ul class="amenities__list">
			<li
				v-for="item in items.slice(0, 8)"
				:key="item.title"
				class="amenities__item convenience"
			>
        <tk-svg :type="icons[item.id]" />
				<div class="convenience__text">
					<div class="convenience__text--head">{{ item.title }}</div>

					<svg
            v-if="+item.price"
						width="12"
						height="12"
						viewBox="0 0 12 12"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M7.3125 0.09375C9.48633 0.09375 11.25 1.79364 11.25 3.89062C11.25 5.98682 9.48633 7.6875 7.3125 7.6875H4.25V8.53125H5.625C6.10898 8.53125 6.5 8.9083 6.5 9.375C6.5 9.8417 6.10898 10.2188 5.625 10.2188H4.25V11.0625C4.25 11.5292 3.85898 11.9062 3.375 11.9062C2.89184 11.9062 2.5 11.5292 2.5 11.0625V10.2188H1.625C1.14184 10.2188 0.75 9.8417 0.75 9.375C0.75 8.9083 1.14184 8.53125 1.625 8.53125H2.5V7.6875H1.625C1.14184 7.6875 0.75 7.31045 0.75 6.84375C0.75 6.37705 1.14184 6 1.625 6H2.5V0.9375C2.5 0.471592 2.89184 0.09375 3.375 0.09375H7.3125ZM9.5 3.89062C9.5 2.7252 8.52109 1.78125 7.3125 1.78125H4.25V6H7.3125C8.52109 6 9.5 5.05605 9.5 3.89062Z"
							fill="#1AC386"
						/>
					</svg>
				</div>
			</li>
		</ul>
		<tk-button
			:height="45"
			font-size="15px"
			line-height="100%"
			class="amenities__more"
			v-if="items.length > 8"
			@click="showAmentities"
		>
			Показать все удобства
		</tk-button>
	</div>
</template>

<script>
import modals from '@/constants/modals'
import amentities from '@/constants/amentities'

export default {
	props: {
		content: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		items() {
			return this.content
				? this.content
						.map((itm) => itm.options)
						.flat()
						.filter((itm) => itm.value)
				: []
		},
		icons() {
			return amentities
		}
	},
	methods: {
		showAmentities() {
			this.$store.commit('showModal', {
				name: modals.AMENITIES,
				props: [...this.content]
			})
		}
	}
}
</script>
