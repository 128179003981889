<template>
	<div class="place-location" id="location">
		<h3 class="section-title place-location__title">Расположение</h3>
		<p class="place-location__address">
			{{ address }}
		</p>
		<div v-if="coords[0]" class="place-location__map">
			<tk-ymaps
				:coords="coords"
				:map-icon="{ src: '/images/map.svg', size: [42, 48] }"
				:isPoint="true"
			/>
		</div>
    <p>
      {{ addressDescription }}
    </p>
	</div>
</template>

<script>
export default {
	props: {
		location: {
			type: Object,
			default: () => ({ lat: 0, lng: 0 })
		},
		address: {
			type: String,
			default: null
		},
    addressDescription: {
      type: String,
      default: null
    }
	},
	computed: {
		coords() {
			return [this.location.lat, this.location.lng]
		}
	}
}
</script>