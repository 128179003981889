<template>
	<div class="reviews">
    <h3 class="reviews__title">Отзывы</h3>
		<div class="rating">
			<tk-svg type="star"/> {{ rating.toFixed(2) }} ({{ count }} отзывов)
		</div>
		<ul class="reviews__list">
			<li class="reviews__item">
				<div class="reviews__item_header">
					<div class="guest-img">
            <tk-image :src="latestReview.author_avatar" :alt="latestReview.author_name" />
					</div>
					<div class="header-wrap">
						<div class="guest-name">{{ latestReview.author_name }}</div>
						<div class="date">{{ getDateInFormat(latestReview.created_at) }}</div>
					</div>
				</div>
				<p class="reviews__item_text" :class="{ shown: latestReview.isShown }" ref="text">
          {{ review.text }}
        </p>
				<div v-if="showMoreBtn" class="read-more-btn" @click="latestReview.isShown = !latestReview.isShown">
					{{ latestReview.isShown ? 'Свернуть' : 'Читать далее' }}
				</div>
			</li>
		</ul>
		<button v-if="count" class="btn reviews__more-btn" @click="showReviewsModal">
			Показать все {{ count }} отзывов
		</button>
	</div>
</template>

<script>
import { getDateInFormat } from "@/utils/dateTime";

export default {
	props: {
		count: {
			type: Number,
			default: 0
		},
		rating: {
			type: Number,
			default: 0
		},
    ratings: {
      type: Object,
      required: true
    },
		review: {
			type: Object,
      required: true
		},
    placeId: {
      type: Number,
      required: true
    },
    name: String
	},
  data() {
    return {
      latestReview: this.review,
      showMoreBtn: false
    }
  },
	methods: {
		showReviewsModal() {
			this.$store.commit('showModal', {
				name: 'ReviewsModal',
				props: {
          placeId: this.placeId,
          rating: this.rating,
          ratings: this.ratings,
          count: this.count
        }
			})
		},
    getDateInFormat
	},
  async beforeMount() {
    const response = await this.$api.users.get({
      ids: [this.review.user_id]
    })
    const user = response.response.users[0]
    this.latestReview.author_name = user.name + " " + user.surname

    for (let i = 0; i < user.avatars.length; i++) {
      if (user.avatars[i].is_active) {
        this.latestReview.author_avatar = user.avatars[i]
        break;
      }
    }
  },
  /**
   * Т.к. "размер" текстового блока комменатрия изначально ограничен
   * (css overflow), проверяем, вмещается ли весь текст в него
   * если да, то не будем отображать кнопку "показать больше", что изменяет
   * видимость / размеры.
   */
  mounted() {
    this.showMoreBtn = (this.$refs.text.offsetHeight < this.$refs.text.scrollHeight)
  }
}
</script>

<style lang="sass" scoped>
.reviews
  .rating
    font-size: 24px
    line-height: 24px
    font-weight: bold
    margin-bottom: 25px
    .icon
      color: #1AC386
  &__title
    margin: 30px 0 20px 0
    font-weight: 700
    font-size: 24px
    line-height: 24px
    color: #3B3F47
  &__list
    list-style: none
    padding-left: 0
    margin-bottom: 40px
    display: flex
    flex-wrap: wrap
    justify-content: space-between
  & .rating
    font-weight: 400
    font-size: 18px
    line-height: 18px
    color: #3B3F47

  &__more-btn
    width: 220px
    height: 45px
    font-style: normal
    font-weight: 700
    font-size: 15px
    color: white
    line-height: 100%
    background: #1AC386
    border-radius: 13px
  &__item
    width: 48%
    font-size: 18px
    line-height: 22px
    &_header
      display: flex
      align-items: center
      margin-bottom: 20px
    .guest-img
      margin-right: 10px
      width: 60px
      height: 60px
      img
        width: 100%
        height: 100%
        border-radius: 50%
        object-fit: cover
    .guest-name
      font-weight: 600
    &_text
      overflow: hidden
      display: -webkit-box
      -webkit-line-clamp: 4
      -webkit-box-orient: vertical
      &.shown
        -webkit-line-clamp: unset

@media screen and (max-width: 912px)
  .reviews
    &__list
      flex-direction: column
    &__item
      width: 100%
</style>
